import { Dispatch, SetStateAction, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SERVER_API } from "utils/constants";
import axios, { AxiosError } from "axios";
import { OrderView } from "./orderview";
import { IoMdClose } from "react-icons/io";
import { FaGear } from "react-icons/fa6";

interface OrderDetails {
  id: number;
  fromAddress: string;
  toAddress: string;
  fromChain: string;
  toChain: string;
  fromAsset: string;
  toAsset: string;
  amount: number;
  orderHash: string;
  vaultAddress: string;
  status: string;
  srcTxid: string;
  dstTxid: string;
}

interface TransactionLoaderProps {
    srctxhash : string;
  setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: number;
  onSwap: () => void;
}

async function fetchOrderDetails(orderId: number): Promise<OrderDetails> {
  if (!orderId) {
    throw new Error("Order ID is required");
  }

  const url = `${SERVER_API}${orderId}`;
  console.log("Requesting URL:", url);

  try {
    const response = await axios.get<OrderDetails>(url);
    console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error("Full error object:", axiosError);
      console.error("Request config:", axiosError.config);
      toast.error(
        `Error fetching order details: ${
          axiosError.response?.data || axiosError.message
        }`
      );
    } else {
      console.error("Non-Axios error:", error);
      toast.error(`Error fetching order details: ${error}`);
    }
    throw error;
  }
}

function useOrderPolling(
  orderId: number,
  setOrderDetails: Dispatch<SetStateAction<OrderDetails>>,
  onSwap: () => void,
  initialPollingState: boolean = true
) {
  const [isPolling, setIsPolling] = useState(initialPollingState);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    if (orderId === 0) {
      return;
    }

    const pollOrderDetails = async () => {
      if (!isPolling) {
        console.log("Polling stopped");
        return;
      }

      try {
        const details = await fetchOrderDetails(orderId);

        setOrderDetails(details);

        if (["completed", "failed" , "cancelled"].includes(details.status)) {
          console.log("Polling stopped due to final status");
          setIsPolling(false);
          onSwap();
        } else {
          timeoutId = setTimeout(pollOrderDetails, 2000);
        }
      } catch (error) {
        console.error("Error during polling:", error);
        timeoutId = setTimeout(pollOrderDetails, 2000);
      }
    };

    pollOrderDetails();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isPolling, onSwap, orderId, setOrderDetails]);

  return { isPolling, setIsPolling };
}

export default function OrderComponent({
    srctxhash,
  setModelOpen,
  orderId,
  onSwap,
}: TransactionLoaderProps) {
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    id: 0,
    fromAddress: "",
    toAddress: "",
    fromChain: "",
    toChain: "",
    fromAsset: "",
    toAsset: "",
    amount: 0,
    orderHash: "",
    vaultAddress: "",
    status: "",
    srcTxid: "",
    dstTxid: "",
  });
  const { isPolling } = useOrderPolling(orderId, setOrderDetails, onSwap);

  const setsrcTxHashnReturnOrder = () => {
      if (srctxhash) {
          const temporder = orderDetails;
          temporder.srcTxid = srctxhash;
          return temporder;
      }

      return orderDetails;
  }

  return (
    <div className="z-50 border-t border-r border-l rounded-2xl border-opacity-60 border-gray-500">
      <div className=" flex w-full justify-between py-5 px-4">
        <div>{isPolling ? <FaGear className="animate-spin text-xl" /> : "Order Details"}</div>
        <div onClick={() => setModelOpen(false)}>
          {" "}
          <IoMdClose className="text-3xl cursor-pointer hover:scale-150 transition" />{" "}
        </div>
      </div>
      {orderDetails.id > 0 ? (
        <OrderView order={setsrcTxHashnReturnOrder()} />
      ) : (
        <div className="text-center text-gray-200">Loading...</div>
      )}
    </div>
  );
}
