import React from 'react';

const Footer = () => {
  return (
    <svg className="fixed bottom-0 mr-5 -mx-1" viewBox="6.5221 141.042 1404.718 88" xmlns="http://www.w3.org/2000/svg">
      <path
        className="svg-prop"
        d="M 6.301 142.148 L 6.301 213.243 C 6.301 221.969 13.465 229.042 22.303 229.042 L 152.324 229.042 C 161.161 229.042 168.326 221.969 168.326 213.243 L 168.326 173.746 C 168.326 165.021 175.49 157.947 184.329 157.947 L 1160.42 157.947 C 1169.258 157.947 1176.422 165.021 1176.422 173.746 L 1176.422 213.243 C 1176.422 221.969 1183.587 229.042 1192.425 229.042 L 1400.767 228.378 C 1409.605 228.378 1411.24 230.374 1411.24 221.648 L 1411.24 142.148"
        fill="#000000"
        stroke="#3A3A3A"
        transform="matrix(-1, 0, 0, -1, -0.000100947307, 0.000005531163)"
      ></path>
    </svg>
  );
};

export default Footer;

// <!doctype html>
// <svg viewBox="6.3011 142.148 1404.939 86.894" xmlns="http://www.w3.org/2000/svg">
// </svg>
