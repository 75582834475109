import { Sepolia, PolygonAmoyTestnet, BaseSepoliaTestnet, ScrollSepoliaTestnet, OpSepoliaTestnet, Chain } from "@thirdweb-dev/chains";

export const SERVER_API = 'https://be-8vhx.onrender.com/order/'
// export const SERVER_API = 'http://localhost:8008/order/'

export const chains: Record<string, Chain> = {
    "evm:sepolia": Sepolia,
    "evm:amoy": PolygonAmoyTestnet,
    "evm:base": BaseSepoliaTestnet,
    "evm:scroll": ScrollSepoliaTestnet,
    "evm:optimism": OpSepoliaTestnet,
    // "evm:linea" : LineaSepolia,
    // "evm:morph" : MorphHolesky,
    // "evm:flow" : Testnet,
    // "evm:hedera" : HederaTestnet,
    // "evm:airdao" : AirdaoTestnet,
};



export const vaultAddressMap: Record<string, string> = {
    'sepolia': '0xAe5a73661222DdC593CC987C186801B45072014b', // Token : 0xa0907fA317E90d6cE330d28565E040f0474E932E
    'base': '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'scroll': '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'amoy': '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'optimism': '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'hedera' : '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'flow' : '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'morph' : '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'linea' : '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'airdao' : '0xDeb4C7AB99e46aFEbfE374F6a7BBFB63D309166f', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
}

export const tokenAddressMap: Record<string, string> = {
    'sepolia': '0xa0907fA317E90d6cE330d28565E040f0474E932E', // Token : 0xa0907fA317E90d6cE330d28565E040f0474E932E
    'base': '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'scroll': '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'amoy': '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    'optimism': '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748Df', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D   
    // 'hedera' : '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    // 'flow' : '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    // 'morph' : '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    // 'linea' : '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
    // 'airdao' : '0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D', // Token : 0xdbfa6D8aC5e5d684E4Fe6B0830242D8A716E748D
}